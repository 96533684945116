import type { Route } from '@angular/router';
import { UserLimitPermissions } from '@backoffice/shared/bo/util-permissions';
import { ngxPermissionsGuard } from 'ngx-permissions';

export const limitReportAdminRoute: Route = {
  path: 'limits',
  loadComponent: () =>
    import('./user-limit-admin-list.component').then(
      (mod) => mod.UserLimitAdminListComponent,
    ),
  children: [
    {
      path: 'preview/:id',
      loadComponent: () =>
        import(
          './feature-user-limit-admin-form/user-limit-admin-form.component'
        ).then((mod) => mod.UserLimitAdminFormComponent),
      children: [
        {
          path: 'history/:tableName',
          loadComponent: () =>
            import('@clean-code/shared/components/ui-auditable-history').then(
              (mod) => mod.AuditableHistoryDetailsComponent,
            ),
        },
      ],
    },
  ],
  canActivate: [ngxPermissionsGuard],
  data: {
    permissions: {
      only: [UserLimitPermissions.userLimitAccess],
    },
  },
};
