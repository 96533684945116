import {
  Component,
  HostBinding,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import type { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { type MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import {
  DashboardFacade,
  type IDashboardListDto,
} from '@clean-code/shared/dashboard/dashboard/domain';
import { TranslocoModule } from '@jsverse/transloco';
import { DashboardReferenceComponent } from '../dashboard-reference/dashboard-reference.component';
import { DashboardEditComponent } from './../dashboard-edit/dashboard-edit.component';

@Component({
  standalone: true,
  templateUrl: './dashboard-add-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatStepperModule,
    MatExpansionModule,
    MatDialogModule,

    TranslocoModule,

    DashboardEditComponent,
    DashboardReferenceComponent,
    ActionButtonModule,
  ],
})
export class DashboardAddDialogComponent {
  @HostBinding('class') class = 'dashboard-dashboard-add-dialog';

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild('editChild', { static: false }) editChild: DashboardEditComponent;
  @ViewChild('referenceChild', { static: false })
  referenceChild: DashboardReferenceComponent;

  public editForm: UntypedFormGroup;
  public referenceForm: UntypedFormControl;
  private facade = inject(DashboardFacade);
  public dialogRef = inject(MatDialogRef<DashboardAddDialogComponent>);
  public dashboard = inject(MAT_DIALOG_DATA) as IDashboardListDto;

  public save() {
    this.facade.add(this.editChild.form.value);
    // this.facade.add$(this.editChild.form.value).subscribe((data) => {
    //   this.router.navigate(['/dashboard', data.id]);
    // });
    this.dialogRef.close();
  }

  public close() {
    this.dialogRef.close();
  }
}
