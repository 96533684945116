import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import {
  settingSpotConfigurationIdentifier,
  settingSpotConfigurationRoute,
} from '@backoffice-frontend/setting-lib';
import { timeSeriesIdentifierRoutes } from '@backoffice-frontend/time-series-identifier/api';
import { forwardPriceCurveRoutes } from '@backoffice/costing/forward-price-curve';
import { loadProfileDetailsRoute } from '@backoffice/load-profile/feature-details';
import { loadProfilesRoute } from '@backoffice/load-profile/feature-list';
import {
  AvailablePermission,
  ContactsPermissions,
  LimitReportPermissions,
} from '@backoffice/shared/bo/util-permissions';
import {
  tradingCalendarHolidaysRoutes,
  tradingCalendarsRoutes,
} from '@clean-code/backoffice/area/util-api';
import { standardizedLoadProfileListRoute } from '@clean-code/backoffice/standardized-load-profile/feature-list';
import { formulaBuilderRoutes } from '@clean-code/feature/formula-builder/api-formula-builder';
import { healthCheckRoute } from '@clean-code/monitoring/feature-healthcheck';
import { NoPermissionLayoutComponent } from '@clean-code/shared/auth/ui-auth';
import { ProfileResolver } from '@clean-code/shared/auth/util-auth';
import { dashboardRoutes } from '@clean-code/shared/dashboard/dashboard/feature-dashboard-view';
import { limitReportAdminRoute } from '@clean-code/user-limit/feature-user-limit-admin-list';
import {
  faBorderAll,
  faBurn,
  faPlug,
  faSignal,
} from '@fortawesome/pro-solid-svg-icons';
import { costingGasPowerRoutes } from 'backoffice/costing/offer-gas';
import { costingOfferPowerRoutes } from 'backoffice/costing/offer-power';
import { forecastDetailsRoute } from 'backoffice/forecast/feature-forecast-details';
import { forecastRoute } from 'backoffice/forecast/forecast-form';
import { forecastListRoute } from 'backoffice/forecast/forecast-list';
import { forecastScaleRoute } from 'backoffice/forecast/forecast-scales-edit-form';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AdminLayoutComponent } from './modules/layout/layout/admin-layout.component';
import { routesLimitReportGas } from './pages/limit-report/limit-reports-gas-route';
import { limitReportOtherRoute } from './pages/limit-report/limit-reports-other-route';
import { limitReportPowerRoute } from './pages/limit-report/limit-reports-power-route';
import { loadProfileRoute } from './pages/load-profile/load-profile/loadProfileRoute';
import { standardizedLoadProfileRoute } from './pages/load-profile/standardized-load-profile/standardized-load-profile/standardizedLoadProfileRoute';

export const appRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [MsalGuard],
    resolve: {
      profile: ProfileResolver,
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((mod) => mod.HomeModule),
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((mod) => mod.HomeModule),
      },
      {
        path: 'notfound',
        loadChildren: () =>
          import('./pages/error-handling/not-found/not-found.module').then(
            (mod) => mod.NotFoundModule,
          ),
      },
      forecastListRoute,
      {
        path: 'forecast-upload',
        loadChildren: () =>
          import('@backoffice/forecast/forecast-lib').then(
            (mod) => mod.ForecastUploadModule,
          ),
      },
      {
        path: 'forecast-upload/:companyId/:energySource',
        loadChildren: () =>
          import('@backoffice/forecast/forecast-lib').then(
            (mod) => mod.ForecastUploadModule,
          ),
      },
      forecastRoute,
      forecastScaleRoute,

      forecastDetailsRoute,
      {
        path: 'forecast-settings',
        loadChildren: () =>
          import('@backoffice/forecast/forecast-lib').then(
            (mod) => mod.ForecastSettingsModule,
          ),
      },
      {
        path: 'forecast-setting/:id',
        loadChildren: () =>
          import('@backoffice/forecast/forecast-lib').then(
            (mod) => mod.ForecastSettingModule,
          ),
      },

      {
        path: 'marketAreas',
        loadChildren: () =>
          import(
            './pages/settings/market-areas/market-areas-management/market-areas-management.module'
          ).then((mod) => mod.MarketAreasManagementModule),
      },
      {
        path: 'marketArea/:id',
        loadChildren: () =>
          import(
            './pages/settings/market-areas/market-area-management/market-area-management.module'
          ).then((mod) => mod.MarketAreaManagementModule),
      },
      {
        path: 'marketArea/:id/:timeSeriesIdentifier/:timeSeriesIdentifierId/:energySource',
        loadChildren: () =>
          import(
            './pages/settings/market-areas/market-area-time-series-identifiers/market-area-time-series-identifier-management/market-area-time-series-identifier-management.module'
          ).then((mod) => mod.MarketAreaTimeSeriesIdentifierManagementModule),
      },
      {
        path: 'deliveryAreas',
        loadChildren: () =>
          import(
            '@clean-code/backoffice/area/feature-delivery-areas-management'
          ).then((mod) => mod.DeliveryAreasManagementModule),
      },
      {
        path: 'deliveryArea/:id',
        loadChildren: () =>
          import(
            '@clean-code/backoffice/area/feature-delivery-area-management'
          ).then((mod) => mod.DeliveryAreaManagementModule),
      },
      {
        path: 'deliveryArea/:id/:balancingEnergy',
        loadChildren: () =>
          import(
            '@clean-code/backoffice/area/feature-delivery-area-balancing-energy'
          ).then((mod) => mod.DeliveryAreaBalancingEnergyModule),
      },
      settingSpotConfigurationRoute,
      settingSpotConfigurationIdentifier,

      {
        path: 'companytype/:id',
        loadChildren: () =>
          import('./pages/segment-management/segment-management.modules').then(
            (mod) => mod.SegmentManagementModule,
          ),
        data: { translate: 'company.types' },
      },
      {
        path: 'companies/:id',
        loadChildren: () =>
          import(
            './pages/contacts/companies-management/companies-management.module'
          ).then((mod) => mod.CompaniesManagementModule),
      },
      {
        path: 'companies',
        loadChildren: () =>
          import(
            './pages/contacts/companies-management/companies-management.module'
          ).then((mod) => mod.CompaniesManagementModule),
        data: { translate: 'company' },
      },
      {
        path: 'company/:id',
        loadChildren: () =>
          import(
            './pages/contacts/company-management/company-management.module'
          ).then((mod) => mod.CompanyManagementModule),
        data: { translate: 'company' },
      },
      {
        path: 'company/:id/readonly',
        loadChildren: () =>
          import(
            './pages/contacts/company-management-readonly/company-management-readonly.module'
          ).then((mod) => mod.CompanyManagementReadOnlyModule),
        data: { translate: 'company' },
      },
      {
        path: 'company-power/:companyId/:energysource',
        loadChildren: () =>
          import(
            './pages/contacts/company-power-management/company-power-management.module'
          ).then((mod) => mod.CompanyPowerManagementModule),
        data: {
          translate: 'company',
          titleIcon: {
            icon: faPlug,
            class: 'power-icon',
          },
        },
      },
      {
        path: 'company-gas/:companyId/:energysource',
        loadChildren: () =>
          import(
            './pages/contacts/company-gas-management/company-gas-management.module'
          ).then((mod) => mod.CompanyGasManagementModule),
        data: {
          translate: 'company',
          titleIcon: {
            icon: faBurn,
            class: 'gas-icon',
          },
        },
      },
      {
        path: 'persons',
        loadChildren: () =>
          import(
            '@clean-code/backoffice/person-management/feature-user-list'
          ).then((mod) => mod.UserManagementListModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ContactsPermissions.customerUserMenue,
          },
        },
      },
      {
        path: 'person/:companyId/:id',
        loadChildren: () =>
          import(
            './pages/contacts/person-management/person-management.module'
          ).then((mod) => mod.PersonManagementModule),
        data: { translate: 'person' },
      },

      {
        path: 'email-template/:id',
        loadChildren: () =>
          import(
            './pages/settings/email-template/email-template-management/email-template-management.module'
          ).then((mod) => mod.EmailTemplateManagementModule),
        data: { translate: 'email-template' },
      },
      {
        path: 'surcharge/:id',
        loadChildren: () =>
          import('backoffice/costing/surcharge').then(
            (mod) => mod.SurchargeModule,
          ),
        data: {
          translate: 'costing.surcharge',
        },
      },
      {
        path: 'hkn-type/:id',
        loadChildren: () =>
          import('backoffice/costing/hkn-types').then(
            (mod) => mod.HknTypeModule,
          ),
        data: {
          translate: 'costing.hkn-type',
        },
      },

      {
        path: 'hkn-identifier/:id',
        loadChildren: () =>
          import(
            './pages/settings/hkn-identifier/hkn-identifier/hkn-identifier.module'
          ).then((mod) => mod.HknIdentifierModule),
        data: {
          translate: 'hkn-identifier',
        },
      },
      {
        path: 'costing',
        children: [
          forwardPriceCurveRoutes,
          {
            path: 'surcharges',
            loadChildren: () =>
              import('backoffice/costing/surcharge').then(
                (mod) => mod.SurchargeListModule,
              ),
          },
          {
            path: 'products',
            loadChildren: () =>
              import('backoffice/costing/product').then(
                (mod) => mod.ProductListModule,
              ),
          },
          {
            path: 'offer-gas',
            loadChildren: () =>
              import('backoffice/costing/offer-gas').then(
                (mod) => mod.OffersGasModule,
              ),
            data: {
              titleIcon: {
                icon: faBurn,
                class: 'gas-icon',
              },
            },
          },
          costingGasPowerRoutes,
          {
            path: 'hkn-types',
            loadChildren: () =>
              import('backoffice/costing/hkn-types').then(
                (mod) => mod.HknTypeListModule,
              ),
          },
          {
            path: 'offer-power',
            loadChildren: () =>
              import('backoffice/costing/offer-power').then(
                (mod) => mod.OffersPowerModule,
              ),
            data: {
              titleIcon: {
                icon: faPlug,
                class: 'power-icon',
              },
            },
          },
          costingOfferPowerRoutes,
        ],
      },
      {
        path: 'settings',
        children: [
          {
            // wrapper module for lazy loading
            path: 'internal-users',
            loadChildren: () =>
              import(
                '@clean-code/backoffice/person-management/feature-user-list'
              ).then((mod) => mod.UserManagementListModule),
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: AvailablePermission.manageUsersPermission,
              },
            },
          },

          {
            path: 'roles',
            children: [
              {
                path: 'frontoffice',
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import(
                        '@clean-code/backoffice/person-management/feature-role-list'
                      ).then((mod) => mod.FrontOfficeRoleManagementListModule),
                  },
                ],
              },
              {
                path: 'frontoffice-int',
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import(
                        '@clean-code/backoffice/person-management/feature-role-list'
                      ).then(
                        (mod) => mod.FrontOfficeIntRoleManagementListModule,
                      ),
                  },
                ],
              },
              {
                path: 'backoffice',
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import(
                        '@clean-code/backoffice/person-management/feature-role-list'
                      ).then((mod) => mod.RoleManagementListModule),
                  },
                ],
              },
            ],
          },
          {
            path: 'companytypes',
            loadChildren: () =>
              import(
                './pages/segments-management/segments-management.module'
              ).then((mod) => mod.SegmentsManagementModule),
          },

          {
            path: 'energy-source',
            loadChildren: () =>
              import('@backoffice-frontend/setting-lib').then(
                (mod) => mod.EnergySourceListModule,
              ),
          },
          {
            path: 'emails-distributor',
            loadChildren: () =>
              import('@backoffice/email/feature-distributor-list').then(
                (mod) => mod.EmailDistributorListModule,
              ),
          },
          {
            path: 'email-templates',
            loadChildren: () =>
              import('@backoffice/email/feature-template-list').then(
                (mod) => mod.EmailTemplateListModule,
              ),
          },
          {
            path: 'hkn-identifier',
            loadChildren: () =>
              import('@backoffice-frontend/setting-lib').then(
                (mod) => mod.HknIdentifierListModule,
              ),
          },
          {
            path: 'norm-cubic-meters',
            loadChildren: () =>
              import(
                './pages/settings/norm-cubic-meter/norm-cubic-meters/norm-cubic-meters.module'
              ).then((mod) => mod.NormCubicMetersModule),
          },
          // {
          //   path: 'norm-cubic-meter/:id',
          //   loadChildren: () =>
          //     import(
          //       './pages/settings/norm-cubic-meter/norm-cubic-meter/norm-cubic-meter.module'
          //     ).then((mod) => mod.NormCubicMeterModule),
          //   data: {
          //     translate: 'settings.norm-cubic-meter',
          //   },
          // },
          {
            path: 'time-series-analysis-identifier',
            loadChildren: () =>
              import(
                './pages/settings/time-series-analysis-identifier/time-series-analysis-identifier.module'
              ).then((mod) => mod.TimeSeriesAnalysisIdentifierModule),
            data: {
              translate: 'time-series-identifiers',
            },
          },
          {
            path: 'time-series-identifier-parser',
            loadChildren: () =>
              import(
                './pages/settings/time-series-identifier-parser/time-series-identifier-parser.module'
              ).then((mod) => mod.TimeSeriesIdentifierParserModule),
            data: {
              translate: 'time-series-identifiers',
            },
          },
          {
            path: 'time-series-identifier-parser-values/:parserId',
            loadChildren: () =>
              import(
                './pages/settings/time-series-identifier-parser-value/time-series-identifier-parser-value.module'
              ).then((mod) => mod.TimeSeriesIdentifierParserValueModule),
            data: {
              translate: 'time-series-identifiers.parser',
            },
          },
          {
            path: 'app-settings',
            loadChildren: () =>
              import('@clean-code/settings/feature-list').then(
                (mod) => mod.SettingsFeatureListModule,
              ),
            data: {
              translate: 'time-series-identifiers',
            },
          },
          {
            path: 'user-company-settings',
            loadChildren: () =>
              import(
                './pages/settings/user-company-setting/user-company-setting.module'
              ).then((mod) => mod.UserCompanySettingModule),
            data: {
              translate: 'time-series-identifiers',
            },
          },
          tradingCalendarsRoutes,
          tradingCalendarHolidaysRoutes,
          timeSeriesIdentifierRoutes,
          formulaBuilderRoutes,
        ],
      },

      loadProfilesRoute,
      loadProfileRoute,
      loadProfileDetailsRoute,
      {
        path: 'load-profile-limits',
        loadChildren: () =>
          import('@backoffice/load-profile/feature-limit-list').then(
            (mod) => mod.LoadProfileLimitListModule,
          ),
      },
      standardizedLoadProfileListRoute,
      standardizedLoadProfileRoute,
      {
        path: 'energy-order',
        children: [
          {
            path: 'gas-contract/:id',
            loadChildren: () =>
              import('@backoffice/energy-order/feature-pages').then(
                (mod) => mod.GasEnergyOrderModule,
              ),
            data: {
              translate: 'energy-order',
              titleIcon: {
                icon: faBurn,
                class: 'gas-icon',
              },
            },
          },
          {
            path: 'power-contract/:id',
            loadChildren: () =>
              import('@backoffice/energy-order/feature-pages').then(
                (mod) => mod.PowerEnergyOrderModule,
              ),
            data: {
              translate: 'energy-order',
              titleIcon: {
                icon: faPlug,
                class: 'power-icon',
              },
            },
          },
          {
            path: 'hkn-contract/:id',
            loadChildren: () =>
              import('@backoffice/energy-order/feature-pages').then(
                (mod) => mod.HknEnergyOrderModule,
              ),
            data: {
              translate: 'energy-order.hkn',
            },
          },
          {
            path: 'pfm-trading-gas',
            loadChildren: () =>
              import('@backoffice/energy-order/feature-pages').then(
                (mod) => mod.PfmTradingGasModule,
              ),
            data: {
              translate: 'energy-order',
              titleIcon: {
                icon: faBurn,
                class: 'gas-icon',
              },
            },
          },
          {
            path: 'pfm-trading-power',
            loadChildren: () =>
              import('@backoffice/energy-order/feature-pages').then(
                (mod) => mod.PfmTradingPowerModule,
              ),
            data: {
              translate: 'energy-order',
              titleIcon: {
                icon: faPlug,
                class: 'power-icon',
              },
            },
          },
        ],
      },
      {
        path: 'limit-reports',
        children: [
          {
            path: 'gas',
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  LimitReportPermissions.limitReportGasAdmin,
                  LimitReportPermissions.limitReportGasEditor,
                ],
              },
            },
            children: [
              {
                path: '',
                children: [routesLimitReportGas],
                data: {
                  energySource: 'GAS',
                  titleIcon: {
                    icon: faBurn,
                    class: 'gas-icon',
                  },
                },
              },
            ],
          },
          {
            path: 'power',
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  LimitReportPermissions.limitReportPowerAdmin,
                  LimitReportPermissions.limitReportPowerEditor,
                ],
              },
            },
            children: [
              {
                path: '',
                children: [limitReportPowerRoute],
                data: {
                  energySource: 'POWER',
                  titleIcon: {
                    icon: faPlug,
                    class: 'power-icon',
                  },
                },
              },
            ],
          },
          {
            path: 'other',
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  LimitReportPermissions.limitReportOtherAdmin,
                  LimitReportPermissions.limitReportOtherEditor,
                ],
              },
            },
            children: [
              {
                path: '',
                children: [limitReportOtherRoute],
                data: {
                  energySource: 'OTHER',
                  titleIcon: {
                    icon: faBorderAll,
                    class: 'other-icon',
                  },
                },
              },
            ],
          },
          {
            path: 'monitoring',
            children: [
              {
                path: 'limit-report-monitoring-legacy-signals',
                loadChildren: () =>
                  import(
                    './pages/limit-report/monitoring/limit-report-monitoring-legacy-signals.module'
                  ).then((mod) => mod.LimitReportMonitoringLegacySignalsModule),
                data: {
                  titleIcon: {
                    icon: faSignal,
                    class: 'other-icon',
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'customer-pools',
        children: [
          {
            path: 'power',
            children: [
              {
                path: '',
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import(
                        './pages/customer-pool/power/customer-pools/customer-pools.module'
                      ).then((mod) => mod.CustomerPoolsModule),
                  },
                ],
                data: {
                  titleIcon: {
                    icon: faPlug,
                    class: 'power-icon',
                  },
                },
              },
              // {
              //   path: 'customer-pools',
              //   loadChildren: () =>
              //     import(
              //       './pages/customer-pool/power/customer-pools/customer-pools.module'
              //     ).then((mod) => mod.CustomerPoolsModule),
              //   data: {
              //     titleIcon: {
              //       icon: faPlug,
              //       class: 'power-icon',
              //     },
              //   },
              // },
              // {
              //   path: 'customer-pool/:id',
              //   loadChildren: () =>
              //     import(
              //       './pages/customer-pool/power/customer-pool/customer-pool.module'
              //     ).then((mod) => mod.CustomerPoolModule),
              //   data: {
              //     translate: 'customer-pools',
              //     titleIcon: {
              //       icon: faPlug,
              //       class: 'power-icon',
              //     },
              //   },
              // },
              // {
              //   path: 'customer-pool-contract/:id/:poolId',
              //   loadChildren: () =>
              //     import(
              //       './pages/customer-pool/power/customer-pool-contract/customer-pool-contract.module'
              //     ).then((mod) => mod.CustomerPoolContractModule),
              //   data: {
              //     translate: 'customer-pool-contracts',
              //     titleIcon: {
              //       icon: faPlug,
              //       class: 'power-icon',
              //     },
              //   },
              // },
            ],
          },
          {
            path: 'gas',
            children: [
              {
                path: '',
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import(
                        './pages/customer-pool/gas/customer-pools/customer-pools.module'
                      ).then((mod) => mod.CustomerPoolsModule),
                  },
                ],
                data: {
                  titleIcon: {
                    icon: faBurn,
                    class: 'gas-icon',
                  },
                },
              },
              // {
              //   path: 'customer-pools',
              //   loadChildren: () =>
              //     import(
              //       './pages/customer-pool/gas/customer-pools/customer-pools.module'
              //     ).then((mod) => mod.CustomerPoolsModule),
              //   data: {
              //     titleIcon: {
              //       icon: faBurn,
              //       class: 'gas-icon',
              //     },
              //   },
              // },
              // {
              //   path: 'customer-pool/:id',
              //   loadChildren: () =>
              //     import(
              //       './pages/customer-pool/gas/customer-pool/customer-pool.module'
              //     ).then((mod) => mod.CustomerPoolModule),
              //   data: {
              //     translate: 'customer-pools',
              //     titleIcon: {
              //       icon: faBurn,
              //       class: 'gas-icon',
              //     },
              //   },
              // },
              // {
              //   path: 'customer-pool-contract/:id/:poolId',
              //   loadChildren: () =>
              //     import(
              //       './pages/customer-pool/gas/customer-pool-contract/customer-pool-contract.module'
              //     ).then((mod) => mod.CustomerPoolContractModule),
              //   data: {
              //     translate: 'customer-pool-contracts',
              //     titleIcon: {
              //       icon: faBurn,
              //       class: 'gas-icon',
              //     },
              //   },
              // },
            ],
          },
        ],
      },
      {
        path: 'statistics',
        children: [
          {
            path: 'power',
            children: [
              {
                path: 'contracts-review/:companyId/year/:year',
                loadChildren: () =>
                  import(
                    './pages/statistics/power/contracts-review/contracts-review.module'
                  ).then((mod) => mod.ContractsReviewModule),
                data: {
                  translate: 'statistics.power',
                  titleIcon: {
                    icon: faPlug,
                    class: 'power-icon',
                  },
                },
              },
            ],
          },
          {
            path: 'gas',
            children: [
              {
                path: 'contracts-review/:companyId/year/:year',
                loadChildren: () =>
                  import(
                    './pages/statistics/power/contracts-review/contracts-review.module'
                  ).then((mod) => mod.ContractsReviewModule),
                data: {
                  translate: 'statistics.gas',
                  titleIcon: {
                    icon: faBurn,
                    class: 'gas-icon',
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'wikis',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@backoffice-frontend/wiki/feature-list').then(
                (mod) => mod.WikiFeatureListModule,
              ),
            data: {
              translate: 'wiki',
            },
          },
          {
            path: 'edit/:id/:parentId',
            loadChildren: () =>
              import('./pages/wikis/wiki/wiki.module').then(
                (mod) => mod.WikiModule,
              ),
            data: {
              translate: 'wiki',
            },
          },
          {
            path: 'edit/:id',
            loadChildren: () =>
              import('./pages/wikis/wiki/wiki.module').then(
                (mod) => mod.WikiModule,
              ),
            data: {
              translate: 'wiki',
            },
          },
        ],
      },
      {
        path: 'controlling',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/controlling/controlling/controlling.module').then(
                (mod) => mod.ControllingModule,
              ),
          },
        ],
      },
      {
        path: 'news',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/news/news/news.module').then(
                (mod) => mod.NewsModule,
              ),
          },
        ],
      },
      {
        path: 'termin',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/termin/termin/termin.module').then(
                (mod) => mod.TerminModule,
              ),
          },
        ],
      },
      {
        path: 'analysis',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/analysis/analysis/analysis.module').then(
                (mod) => mod.AnalysisModule,
              ),
          },
        ],
      },
      {
        path: 'contracts',
        children: [
          {
            path: 'power',
            loadChildren: () =>
              import('@backoffice-frontend/contracts/feature-power-list').then(
                (mod) => mod.ContractsFeaturePowerListModule,
              ),
            data: {
              translate: 'contracts.power',
              titleIcon: {
                icon: faPlug,
                class: 'power-icon',
              },
            },
          },
          {
            path: 'gas',
            loadChildren: () =>
              import('./pages/contracts/contract-gas/contract-gas.module').then(
                (mod) => mod.ContractGasModule,
              ),
            data: {
              translate: 'contracts.gas',
              titleIcon: {
                icon: faBurn,
                class: 'gas-icon',
              },
            },
          },
        ],
      },
      dashboardRoutes,
      {
        path: 'market',
        loadChildren: () =>
          import('@backoffice-frontend/market/feature-dashboard').then(
            (mod) => mod.MarketFeatureDashboardModule,
          ),
        data: { canEdit: true },
      },
      limitReportAdminRoute,
      healthCheckRoute,
      // {
      //   path: 'healthcheck',
      //   loadChildren: () =>
      //     import('./pages/healthcheck/healthcheck.module').then(
      //       (mod) => mod.HealthcheckModule
      //     ),
      //   data: {},
      // },
      {
        path: 'monitoring/report-power-signals',
        loadChildren: () =>
          import(
            './pages/limit-report/monitoring/signal-monitoring/signal-monitoring-power/signal-monitoring-power.module'
          ).then((mod) => mod.SignalMonitoringPowerModule),
        data: {
          translate: 'report-power-signal',
        },
      },
      {
        path: 'monitoring/report-gas-signals',
        loadChildren: () =>
          import(
            './pages/limit-report/monitoring/signal-monitoring/signal-monitoring-gas/signal-monitoring-gas.module'
          ).then((mod) => mod.SignalMonitoringGasModule),
        data: {
          translate: 'report-gas-signal',
        },
      },
      {
        path: 'monitoring/report-other-signals',
        loadChildren: () =>
          import(
            './pages/limit-report/monitoring/signal-monitoring/signal-monitoring-other/signal-monitoring-other.module'
          ).then((mod) => mod.SignalMonitoringOtherModule),
        data: {
          translate: 'report-other-signal',
        },
      },

      {
        path: 'monitoring/pool-power-signals',
        loadChildren: () =>
          import(
            './pages/monitoring/pool-signal-monitoring-power/pool-signal-monitoring-power.module'
          ).then((mod) => mod.PoolSignalMonitoringPowerModule),
        data: {
          translate: 'pool-power-signal',
        },
      },
      {
        path: 'monitoring/pool-power-spread-signals',
        loadChildren: () =>
          import(
            './pages/monitoring/pool-spread-signal-monitoring-power/pool-spread-signal-monitoring-power.module'
          ).then((mod) => mod.PoolSpreadSignalMonitoringPowerModule),
        data: {
          translate: 'pool-power-spread-signal',
        },
      },
      {
        path: 'monitoring/pool-gas-signals',
        loadChildren: () =>
          import(
            './pages/monitoring/pool-signal-monitoring-gas/pool-signal-monitoring-gas.module'
          ).then((mod) => mod.PoolSignalMonitoringGasModule),
        data: {
          translate: 'pool-gas-signal',
        },
      },

      // {
      //   path: 'demo',
      //   loadComponent: () =>
      //     import('./pages/demo/demo.component').then(
      //       (mod) => mod.DemoComponent,
      //     ),
      //   // canLoad: [DevEnvGuard],
      // },
    ],
  },
  {
    path: '',
    component: NoPermissionLayoutComponent,
    children: [
      {
        path: 'forbidden',
        loadChildren: () =>
          import('@clean-code/shared/auth/ui-auth').then(
            (mod) => mod.ForbiddenModule,
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
