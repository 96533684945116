import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  computed,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule, type MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthStore } from '@clean-code/shared/auth/util-auth';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import {
  DashboardMenuFacade,
  DashboardPermission,
  type IDashboardListDto,
} from '@clean-code/shared/dashboard/dashboard/domain';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faEdit,
  faEllipsisV,
  faEyeSlash,
  faGridHorizontal,
  faPlus,
  faShareNodes,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { from, map } from 'rxjs';
import { DashboardAddDialogComponent } from './dashboard-add-dialog/dashboard-add-dialog.component';
import { DashboardEditDialogComponent } from './dashboard-edit-dialog/dashboard-edit-dialog.component';
import { DashboardReferenceDialogComponent } from './dashboard-reference-dialog/dashboard-reference-dialog.component';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[dashboard-menu]',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss'],
  imports: [
    CommonModule,
    RouterModule,

    MatExpansionModule,
    MatMenuModule,
    MatButtonModule,

    FontAwesomeModule,
    TranslocoModule,
    NgxPermissionsModule,
    ProgressBarComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardMenuComponent {
  @ViewChild('menuTrigger', { static: false }) menuTrigger: MatMenuTrigger;

  private router = inject(Router);
  private confirmService = inject(ConfirmService);
  public dashboardMenuFacade = inject(DashboardMenuFacade);
  public permissionService = inject(NgxPermissionsService);

  public faGridHorizontal = faGridHorizontal;
  public faEllipsisV = faEllipsisV;
  public faEdit = faEdit;
  public faTrashAlt = faTrashAlt;
  public faPlus = faPlus;
  public faShare = faShareNodes;
  public faEyeSlash = faEyeSlash;

  public permissions = DashboardPermission;

  public isLoading$ = this.dashboardMenuFacade.isLoading$;

  public hasDashboardAdminPermission$ = from(
    this.permissionService.hasPermission(DashboardPermission.admin),
  );

  public assigned = this.dashboardMenuFacade.assigned;
  // public hasUserPermission = this.dashboardFacade.hasUserPermission;
  // public getActiveId = this.dashboardFacade.getActiveId;

  #authStore = inject(AuthStore);
  public sub = computed(() => this.#authStore?.user().sub);
  public id = this.#authStore.id;

  private activatedRoute = inject(ActivatedRoute);
  public id$ = this.activatedRoute.params.pipe(map((params) => params.id));

  public addDashboard($event: Event) {
    const inputDashboard = {
      color: '#cdcdcd',
      name: null,
      description: null,
      shared: false,
      visibleTo: [],
    } as Partial<IDashboardListDto>;

    this.confirmService
      .open('', '', DashboardAddDialogComponent, inputDashboard, {
        width: '800px',
        height: '600px',
      })
      .subscribe();

    $event.stopPropagation();
  }

  public addReference($event: Event) {
    const inputDashboard = {
      color: '#cdcdcd',
      shared: false,
      visibleTo: [],
    } as IDashboardListDto;

    this.confirmService.open(
      '',
      '',
      DashboardReferenceDialogComponent,
      inputDashboard,
      { width: '800px', height: '600px' },
    );

    $event.stopPropagation();
  }

  public editDashboard($event: Event, d: IDashboardListDto) {
    if (d.creator.id !== this.sub()) {
      $event.stopPropagation();
      return;
    }

    this.confirmService
      .open('', '', DashboardEditDialogComponent, d, {
        width: '600px',
        height: '634px',
      })
      .subscribe();

    this.menuTrigger.closeMenu();
    $event.stopPropagation();
  }

  public removeDashboard($event: Event, d: IDashboardListDto) {
    this.confirmService
      .confirm(
        'DASHBOARDPANEL.DIALOG-TITLE.REMOVE.TITLE',
        'DASHBOARDPANEL.DIALOG-TITLE.REMOVE.BODY',
      )
      .subscribe((result: boolean) => {
        if (result) {
          this.dashboardMenuFacade
            .remove$(d)
            .subscribe((innerResult: boolean) => {
              if (innerResult) {
                this.dashboardMenuFacade.removeFromState(d.id);
                this.router.navigate(['/']);
              }
            });
        }
      });

    this.menuTrigger.closeMenu();
    $event.stopPropagation();
  }

  public deleteDashboard($event: Event, d: IDashboardListDto) {
    if (d.creator.id !== this.sub()) {
      $event.stopPropagation();
      return;
    }

    this.confirmService
      .confirm(
        'DASHBOARDPANEL.DIALOG-TITLE.DELETE.TITLE',
        'DASHBOARDPANEL.DIALOG-TITLE.DELETE.BODY',
      )
      .subscribe((result: boolean) => {
        if (result) {
          this.dashboardMenuFacade
            .delete$(d.id)
            .subscribe((innerResult: boolean) => {
              if (innerResult) {
                this.dashboardMenuFacade.removeFromState(d.id);
                this.router.navigate(['/']);
              }
            });
        }
      });

    this.menuTrigger.closeMenu();
    $event.stopPropagation();
  }
}
