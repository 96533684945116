import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import type { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import {
  DataSource,
  TimeSeriesFacade,
} from '@backoffice-frontend/time-series-identifier/domain';
import { indicate } from '@clean-code/shared/common';
import type { IDashboardWidgetDetailDto } from '@clean-code/shared/components/ui-dashboard';
import { DevExtremeLineChartComponent } from '@clean-code/shared/components/ui-devextreme-charts';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { TimeSpanToggleComponent } from '@clean-code/shared/components/ui-time-span-toggle';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons';
import { RxIf } from '@rx-angular/template/if';
import type { TitleOptions } from 'chart.js';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { chartData$ } from '../helper/chart.helper';

@Component({
  standalone: true,
  selector: 'chart-widget-dialog',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    MatDialogModule,
    FontAwesomeModule,
    RxIf,

    TimeSpanToggleComponent,
    ProgressBarComponent,
    DevExtremeLineChartComponent,
  ],
})
export class ChartDialogComponent {
  @HostBinding('class') class = 'chart-widget-dialog';

  #dialogRef = inject(MatDialogRef<ChartDialogComponent>);
  #timeSeriesFacade = inject(TimeSeriesFacade);
  #dialogData: {
    widget: IDashboardWidgetDetailDto;
    timeFrame: TimeFrame;
    dataSource: DataSource;
  };

  public selectedTimeFrame$ = new BehaviorSubject<TimeFrame>('M_3');
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public yAxisTitle$ = new BehaviorSubject<TitleOptions>(undefined);

  @Input()
  public legend = false;

  public title: string;
  public faXmarkLarge = faXmarkLarge;
  data$ = this.selectedTimeFrame$.pipe(
    switchMap((timeFrame: TimeFrame) =>
      chartData$(
        this.#timeSeriesFacade,
        [this.#dialogData.dataSource.settings.identifier],
        timeFrame,
      ).pipe(indicate(this.isLoading$)),
    ),
    tap((values: any) => {
      // this.errors = values.errors;
      // this.infoDate = values.infoDate;
      // this.info = values.info;
      this.title = values.array[0]?.label;
      this.yAxisTitle$.next(values.yAxisTitle);
    }),
    map((values: any) => values.array),
  );

  constructor() {
    this.#dialogData = inject<{
      widget: IDashboardWidgetDetailDto;
      timeFrame: TimeFrame;
      dataSource: DataSource;
    }>(MAT_DIALOG_DATA);
    this.selectedTimeFrame$.next(this.#dialogData.timeFrame);
  }

  onNoClick(): void {
    this.#dialogRef.close();
  }

  onClickClose(): void {
    this.#dialogRef.close();
  }
}
